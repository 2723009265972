



























/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import mixins from "@/mixin";
import routerMixin from "@/mixin/router-mixin.ts";
import updateTdk from "@/utils/tdk";

import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "WealList",
  components: { NavBar, BlockBox, AppBlockNormal },
  mixins: [mixins, routerMixin]
})
export default class WealList extends Vue {
  // 初始数据可以直接声明为实例的属性
  private block: any = {};
  private navs: any[] = [];
  private tdk(self: any) {
    return {
      title: `-${self.$route.query.source_block_name}`,
    };
  }
  private serverPrefetch(): any {
    updateTdk(this);
  }
  private mounted(): void {
    const self: any = this;
    const ops = self.getPrevParams(null, "");
    self.navs.push({
      name: ops.block_name
    });
    self.getData();
  }
  private getData(more?: string): any {
    const self: any = this;
    updateTdk(this);
    const ops = self.getPrevParams(null, "");
    const rankId: number = ops.rank_id; // Number(self.utils.getUrlParam('source_rank_id')) || 0;
    const blockType: string = ops.block_type;
    const blockId: number = ops.block_id;
    if (!blockId) {
      this.$router.push({
        name: "index"
      });
      return;
    }
    if (!more) {
      self.$doMotion(self, "LOADING_SWITCH", true);
    }
    return this.$store
      .dispatch("fetchWealList", {
        context: this,
        params: {
          type: blockType,
          id: blockId,
          start: self.currIndex,
          max: self.pageMax
        }
      })
      .then((result: any) => {
        if (result.value) {
          const block: any = {
            ...ops,
            data: result.value,
            data_source: "wealList",
            blockLink: "wealDetail"
          };
          if (self.block.data) {
            block.data = self.block.data.concat(block.data)
          }
          self.block = block
          self.dataMore = block.more;
          // 添加曝光事件上报
          self.$doMotion(self, "LOADING_SWITCH", false);
          if (!more) {
            self.loadStatus1 = "ready" + self.currIndex;
          } else {
            self.loadStatus1 = "loaded" + self.currIndex;
          }
        }
      })
      .catch(err => {
        self.$toast.show(err);
        self.loadStatus1 = "end" + self.currIndex;
      });
  }
}
